@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    transition: all 1s ease-in-out;
    direction: rtl;
}

ul {
    list-style: none;
}

.hero-bg {
    background-image: url(../public/assets/hero.webp);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}
body{
    font-family: 'Tajawal', sans-serif;
}

.overlay {
    background-color: #000000bd;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

.overlay-service {
    background-color: #011f32aa;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
}

@layer utilities {
    .max-container {
        @apply mx-auto max-w-[1400px];
    }
    .padding-container {
        @apply xs:px-6 lg:px-20 3xl:px-0;
    }
    .flex-center {
        @apply flex items-center justify-center;
    }
    .main-prop {
        padding: 60px 0;
    }
}